var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      [
        _vm.jobDataInner
          ? _c(
              "base-detail-card",
              { attrs: { title: "Delivered Information" } },
              [
                _c(
                  "el-form",
                  {
                    ref: "deliveredScheduleForm",
                    attrs: {
                      model: _vm.jobDataInner,
                      "label-position": "top",
                      "validate-on-rule-change": false,
                    },
                  },
                  [
                    _c("div", { staticClass: "contract-content" }, [
                      _c("div", { staticClass: "fields" }, [
                        _c(
                          "div",
                          { staticClass: "classification" },
                          [
                            _c("el-row", { attrs: { gutter: 10 } }, [
                              _c(
                                "div",
                                { staticClass: "product-container product" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "single-row-container" },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: { label: "Product Name" },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  readonly: "",
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).productName,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "Barge Name" } },
                                            [
                                              _c("el-input", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  readonly: "",
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).bargeName,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "eBDN No" } },
                                            [
                                              _c("el-input", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  readonly: "",
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).docNumber,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "eBDN Date" } },
                                            [
                                              _c("el-date-picker", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  type: "datetime",
                                                  format: "dd-MM-yyyy HH:mm",
                                                  readonly: "",
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                },
                                                model: {
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).docDate,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.jobDataInner || {},
                                                      "docDate",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "(jobDataInner||{}).docDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "Delivered Quantity",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  readonly: "",
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).docQty,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            { attrs: { label: "ETC" } },
                                            [
                                              _c("el-date-picker", {
                                                staticClass: "single-border",
                                                attrs: {
                                                  type: "datetime",
                                                  format: "dd-MM-yyyy HH:mm",
                                                  readonly: "",
                                                  "value-format":
                                                    "yyyy-MM-dd HH:mm:ss",
                                                },
                                                model: {
                                                  value: (
                                                    _vm.jobDataInner || {}
                                                  ).etc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.jobDataInner || {},
                                                      "etc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "(jobDataInner||{}).etc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          (_vm.jobDataInner || {}).bdnPdfId
                                            ? _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "min-width": "180px",
                                                  },
                                                  attrs: {
                                                    label: "BDN Documents",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-link",
                                                    {
                                                      staticClass: "view-pdf",
                                                      attrs: {
                                                        icon: "el-el-icon-document-edit",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.previewFileMixin(
                                                            (
                                                              _vm.jobDataInner ||
                                                              {}
                                                            ).bdnPdfId
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("u", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.$t("viewEBDN")
                                                          )
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }